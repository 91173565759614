import React from "react";
import "./modalEdit.css";

import { useState, useEffect } from "react";

const ModalEdit = ({ isOpen, onClose, title, children, offreInfos, handleSubmitEdit, setTabOffre }) => {
    if (!isOpen) return null;

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setTabOffre({ ...offreInfos, [id]: newValue });
    };

    console.log(offreInfos);


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{title}</h2>
                <form className='form-offre'>
                    <div className='container-form'>
                        <div className='form-left'>
                            <div className='input-group'>
                                <label htmlFor='titre'>Titre de l'offre</label>
                                <input type='text' id='intitule' onChange={handleChange} value={offreInfos.intitule} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='description'>Description</label>
                                <textarea id='description' onChange={handleChange} value={offreInfos.description} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='secteur'>Secteur d'activité</label>
                                <input type='text' id='secteur' onChange={handleChange} value={offreInfos.secteur} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='type'>Type de contrat</label>
                                <input type='text' id='type_emploi' onChange={handleChange} value={offreInfos.type_emploi} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='lieu'>Lieu de travail</label>
                                <input type='text' id='lieu' onChange={handleChange} value={offreInfos.lieu} />
                            </div>

                        </div>

                        <div className='form-right'>

                            <div className='input-group'>
                                <label htmlFor='experience'>Expérience</label>
                                <input type='text' id='annee_experience' onChange={handleChange} value={offreInfos.annee_experience} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='diplome'>Diplôme</label>
                                <input type='text' id='diplome_requit' onChange={handleChange} value={offreInfos.diplome_requit} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='lien'>Lien LinkedIn</label>
                                <input type='text' id='link' onChange={handleChange} value={offreInfos.link} />
                            </div>
                        </div>
                    </div>
                    <div className="btn-group" onClick={() => handleSubmitEdit(offreInfos)}>
                        Modifier
                    </div>
                </form>
                <button className="modal-close" onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
};

export default ModalEdit;
