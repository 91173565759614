import React, { useState, useEffect } from 'react';

import "./dashboard.css";

import { TbLogout2 } from "react-icons/tb";
import { FaRegEdit } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { GoUpload } from "react-icons/go";
import { FaSortAlphaUp } from "react-icons/fa";
import { FaSortAlphaDown } from "react-icons/fa";

import AutoComplete from "../../components/autocomplete/PE-AutoComplete.js";

import { toast, Toaster } from 'react-hot-toast';
import { type } from '@testing-library/user-event/dist/type';

const Dashboard = () => {
    const [idOfCatSelected, setIdOfCatSelected] = useState(0);
    const [listclients, setListClients] = useState([]);
    const [listDocuments, setListDocuments] = useState([]);

    const [itemSelectedName, setItemSelectedName] = useState('');
    const [infoItemSelected, setInfoItemSelected] = useState({});

    const [createModeClient, setCreateModeClient] = useState(false);
    const [createModeDocument, setCreateModeDocument] = useState(false);

    const [filter, setFilter] = useState('');

    const [infoCreateClient, setInfoCreateClient] = useState({
        name: '',
        email: '',
        entreprise: '',
        password: ''
    });

    const [infoCreateDocument, setInfoCreateDocument] = useState({
        client: '',
        file: '',
        type: '',
        nomDocument: ''
    });

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (!token) {
            window.localStorage.clear();
            window.location.href = '/login';
        }
    }, []);

    useEffect(() => {
        fetchClients();
        fetchDocuments();
    }, []);

    useEffect(() => {
        if (filter === '') {
            fetchClients();
        }

        filterClients();
    }, [filter]);

    const changeIdOfCatSelected = (id) => {
        setIdOfCatSelected(id);
    }

    const generateRandomPassword = () => {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }


    const fetchClients = () => {
        fetch(`${process.env.REACT_APP_API_URL}/clients/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('token'),
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },
        })
            .then(response => response.json())
            .then(data => {
                setListClients(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const fetchDocuments = () => {
        fetch(`${process.env.REACT_APP_API_URL}/files/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem('token'),
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setListDocuments(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const publishItem = () => {
        if (createModeClient) {
            if (infoCreateClient.name === '' || infoCreateClient.email === '' || infoCreateClient.entreprise === '') {
                toast.error('Veuillez remplir tous les champs');
                return;
            }
            fetch(`${process.env.REACT_APP_API_URL}/clients/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('token'),
                    'userId': JSON.parse(window.localStorage.getItem('user')).user.id
                },
                body: JSON.stringify(infoCreateClient)
            })
                .then(response => response.json())
                .then(data => {
                    fetchClients();
                    setCreateModeClient(false);
                    setInfoCreateClient({
                        name: '',
                        email: '',
                        entreprise: '',
                        password: ''
                    });
                    setItemSelectedName('');
                    toast.success('Client créé avec succès');
                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else if (createModeDocument) {
            console.log(infoCreateDocument);
            if (infoCreateDocument.client === '' || infoCreateDocument.file === '' || infoCreateDocument.type === '') {
                toast.error('Veuillez remplir tous les champs');
                return;
            }
                    
            // Appel de fileToBase64 qui retourne une Promise
            fileToBase64(infoCreateDocument.file)
                .then(base64String => {                    
                    // Envoi de la requête avec la chaîne Base64
                    fetch(`${process.env.REACT_APP_API_URL}/files/upload`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': window.localStorage.getItem('token'),
                            'userId': JSON.parse(window.localStorage.getItem('user')).user.id
                        },
                        body: JSON.stringify({
                            client_id: infoCreateDocument.client,
                            type: infoCreateDocument.type,
                            nom_du_fichier: infoCreateDocument.nomDocument,
                            fichier: base64String // Utilisation de la chaîne Base64 ici
                        })
                    })
                    .then(data => {
                        fetchDocuments();
                        setCreateModeDocument(false);
                        setInfoCreateDocument({
                            client: '',
                            file: '',
                            type: ''
                        });
                        setItemSelectedName('');
                        toast.success('Document créé avec succès');
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }        
    }

    const deleteItem = () => {
        let check = window.confirm('Voulez-vous vraiment supprimer cet élément ?');

        if (!check) {
            return;
        }

        if (itemSelectedName !== '' && idOfCatSelected === 1) {
            fetch(`${process.env.REACT_APP_API_URL}/clients/delete/${infoItemSelected.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('token'),
                    'userId': JSON.parse(window.localStorage.getItem('user')).user.id
                }
            })
                .then(response => response.json())
                .then(data => {
                    fetchClients();
                    setCreateModeClient(false);
                    setCreateModeDocument(false);
                    setInfoCreateClient({
                        name: '',
                        email: '',
                        entreprise: '',
                        password: ''
                    });
                    setItemSelectedName('');
                    toast.success('Client supprimé avec succès');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else if (itemSelectedName !== '' && idOfCatSelected === 0) {
            console.log(infoItemSelected);

            fetch(`${process.env.REACT_APP_API_URL}/files/delete/${infoItemSelected.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('token'),
                    'userId': JSON.parse(window.localStorage.getItem('user')).user.id
                }
            })
                .then(data => {
                    fetchDocuments();
                    setCreateModeClient(false);
                    setCreateModeDocument(false);
                    setInfoCreateDocument({
                        client: '',
                        file: '',
                        type: '',
                        nomDocument: ''
                    });
                    setItemSelectedName('');
                    toast.success('Document supprimé avec succès');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const formatDate = (date) => {
        let d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const updateItem = () => {
        if (infoItemSelected.name === '' || infoItemSelected.email === '' || infoItemSelected.entreprise === '') {
            toast.error('Veuillez remplir tous les champs');
            return;
        }

        if (itemSelectedName !== '') {
            fetch(`${process.env.REACT_APP_API_URL}/clients/update/${infoItemSelected.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.localStorage.getItem('token'),
                    'userId': JSON.parse(window.localStorage.getItem('user')).user.id
                },
                body: JSON.stringify(infoItemSelected)
            })
                .then(response => response.json())
                .then(data => {
                    fetchClients();
                    toast.success('Client modifié avec succès');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const filterClients = () => {
        if (filter === '') {
            fetchClients();
            return;
        }

        listclients.filter((client) => {
            setListClients(listclients.filter((client) => client.name.toLowerCase().includes(filter.toLowerCase())));
        });
    }

    const clear = () => {
        setCreateModeDocument(false);
        setCreateModeClient(false);
        setItemSelectedName('');
        setInfoItemSelected('');
        setInfoCreateClient({
            name: '',
            email: '',
            entreprise: '',
            password: ''
        });
        setInfoCreateDocument({
            client: '',
            file: '',
            type: '',
            nomDocument: ''
        });        
    }

    const sortClient = (order) => {
        if (order === 'asc') {
            let sorted = listclients.sort((a, b) => a.name.localeCompare(b.name));
            setListClients(sorted);
        } else {
            let sorted = listclients.sort((a, b) => b.name.localeCompare(a.name));
            setListClients(sorted);
        }

        clear();
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setInfoCreateDocument({
            ...infoCreateDocument,
            file: file
        });
    }

    const fileToBase64 = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                resolve(base64String);
            };

            reader.onerror = error => reject(error);

            reader.readAsDataURL(file);
        });
    }
      

    const downloadFile = (fileData) => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${fileData}`;

        link.download = 'document.pdf';
        link.click();
    };

    const getClientName = (id) => {
        let name = '';
        listclients.map((client) => {
            if (client.id === id) {
                name = client.name;
            }
        });

        return name;
    }

    return (
        <div className='pageDash'>
            <div className='headerDash'>
                <div className='left-dash-header'>
                    <h1>
                        sarl_perrollaz_gestion
                    </h1>

                    <a href='/gestionOffre'>
                        Gestion des offres
                    </a>
                </div>


                <div className='logout'>
                    <TbLogout2 size={30} />
                </div>
            </div>

            <div className='dashboard'>
                <div className='tableDb'>
                    <div className='nameCat'>
                        <h1>
                            Catégories
                        </h1>
                    </div>

                    <div className='catElements'>
                        <div onClick={() => {
                                changeIdOfCatSelected(0);
                                clear();
                                fetchDocuments();
                            }} className={`catElement ${idOfCatSelected === 0 ? 'selected' : ''}`}>
                            <h1>
                                Documents
                            </h1>

                            <div className='go'>
                                <FaAngleRight size={15} />
                            </div>
                        </div>

                        <div onClick={() => {
                                changeIdOfCatSelected(1);
                                clear();
                                fetchClients();
                            }} 
                            className={`catElement ${idOfCatSelected === 1 ? 'selected' : ''}`}>
                            <h1>
                                Clients
                            </h1>

                            <div className='go'>
                                <FaAngleRight size={15} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='itemsTable'>
                    <div className='nameCat'>
                        <div className='headerCat'>
                            <h1>
                                {idOfCatSelected === 0 ? 'Documents' : 'Clients'}
                            </h1>

                            <div className='edit' >
                                <div className='alpha' onClick={() => sortClient('asc')}>
                                    <FaSortAlphaUp size={25} />
                                </div>

                                <div className='alpha' onClick={() => sortClient('desc')}>
                                    <FaSortAlphaDown size={25} />
                                </div>

                                <div onClick={() => {
                                    if (idOfCatSelected === 0) {
                                        setCreateModeDocument(true);
                                        setCreateModeClient(false);
                                        setItemSelectedName('Créer un document');
                                        setInfoItemSelected('');
                                        setInfoCreateDocument({
                                            client: '',
                                            file: '',
                                            type: '',
                                            nomDocument: ''
                                        });
                                    } else {
                                        setCreateModeClient(true);
                                        setCreateModeDocument(false);
                                        setItemSelectedName('Créer un client');
                                        setInfoItemSelected('');
                                        setInfoCreateClient({
                                            name: '',
                                            email: '',
                                            entreprise: '',
                                            password: generateRandomPassword()
                                        });
                                    }
                                }} className='but'>
                                    <FaRegEdit size={25} />
                                </div>
                            </div>
                        </div>
                        <input type='text' placeholder='Rechercher...' value={filter} onChange={(e) => {setFilter(e.target.value)}} />
                    </div>
                    
                    <div className='offset'>
                        {idOfCatSelected === 1 && (
                            <div className='clientList'>
                                {listclients && listclients.map((client, index) => (
                                    <div key={index} className={`item ${client.name === itemSelectedName ? 'current' : ''}`} onClick={() => {
                                        setItemSelectedName(client.name);
                                        setInfoItemSelected(client);
                                        setCreateModeClient(false);
                                        setCreateModeDocument(false);
                                    }}>
                                        <h1>{client.name}</h1>

                                        <div className='go'>
                                            <FaAngleRight size={15} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {idOfCatSelected === 0 && (
                            <div className='clientList'>
                                {listDocuments && listDocuments.map((document, index) => (
                                    <div key={index} className={`item ${document.nom_du_fichier === itemSelectedName ? 'current' : ''}`} onClick={() => {
                                        setItemSelectedName(document.nom_du_fichier);
                                        setInfoItemSelected(document);
                                        setCreateModeClient(false);
                                        setCreateModeDocument(false);
                                    }}>
                                        <h1>
                                        {
                                            document.nom_du_fichier.length > 20 ? document.nom_du_fichier.substring(0, 20) + '...' : document.nom_du_fichier
                                        }
                                        </h1>

                                        <div className='go'>
                                            <FaAngleRight size={15} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className='item'>
                    <div className='nameCat'>
                        <h1>
                            {
                                createModeClient ? 'Créer un client' : (itemSelectedName !== '' ? itemSelectedName : 'Aucun élément sélectionné')
                            }
                        </h1>

                        <div className='close' onClick={() => {
                            clear();
                        }}>
                            <IoMdClose size={25} />
                        </div>
                    </div>

                    {
                        idOfCatSelected == 1 && !createModeClient && !createModeDocument && itemSelectedName !== '' && (
                            <div className='itemContent'>
                                <div className='nameItem'>
                                    <h2>
                                        Nom:
                                    </h2>
                                    <input type='text' placeholder={itemSelectedName} value={infoItemSelected.name} onChange={(e) => setInfoItemSelected({ ...infoItemSelected, name: e.target.value })} />
                                </div>

                                <div className='email'>
                                    <h2>
                                        Email:
                                    </h2>
                                    <input type='text' placeholder={infoItemSelected.email} value={infoItemSelected.email} onChange={(e) => setInfoItemSelected({ ...infoItemSelected, email: e.target.value })} />
                                </div>

                                <div className='company'>
                                    <h2>
                                        Entreprise:
                                    </h2>
                                    <input type='text' placeholder={infoItemSelected.entreprise} value={infoItemSelected.entreprise} onChange={(e) => setInfoItemSelected({ ...infoItemSelected, entreprise: e.target.value })} />
                                </div>

                                <div className='password'>
                                    <h2>
                                        Mot de passe:
                                    </h2>
                                    <input type='text' value={infoItemSelected.password} />
                                </div>
                            </div>
                        )
                    }

                    {
                        idOfCatSelected == 0 && !createModeClient && !createModeDocument && itemSelectedName !== '' && (
                            <div className='itemContent'>
                                <div className='nameItem create'>
                                    <h2>
                                        Créé le: {infoItemSelected.created_at ? formatDate(infoItemSelected.created_at) : ''}
                                    </h2>
                                </div>

                                <div className='nameItem'>
                                    <h2>
                                        Nom du document:
                                    </h2>
                                    <input type='text' placeholder={itemSelectedName} value={infoItemSelected.nom_du_fichier} />
                                </div>

                                <div className='document'>
                                    <h2>
                                        Document:
                                    </h2>
                                    
                                    <button onClick={() => downloadFile(infoItemSelected.fichier)}>
                                        Télécharger
                                    </button>
                                </div>

                                <div className='clientDoc'>
                                    <h2>
                                        Client:
                                    </h2>
                                    <input type='text' value={getClientName(infoItemSelected.client_id)} />
                                </div>

                                <div className='typeDoc'>
                                    <h2>
                                        Type:
                                    </h2>
                                    <input type='text' value={infoItemSelected.type} />
                                </div>
                            </div>
                        )
                    }


                    {
                        createModeClient && itemSelectedName !== '' && (
                            <div className='itemContent'>
                                <div className='nameItem'>
                                    <h2>
                                        Nom:
                                    </h2>
                                    <input type='text' placeholder='Nom et prénom' value={infoCreateClient.name} onChange={(e) => setInfoCreateClient({ ...infoCreateClient, name: e.target.value })} />
                                </div>

                                <div className='email'>
                                    <h2>
                                        Email:
                                    </h2>
                                    <input type='text' placeholder='Email' value={infoCreateClient.email} onChange={(e) => setInfoCreateClient({ ...infoCreateClient, email: e.target.value })} />
                                </div>

                                <div className='company'>
                                    <h2>
                                        Entreprise:
                                    </h2>
                                    <input type='text' placeholder='Entreprise' value={infoCreateClient.entreprise} onChange={(e) => setInfoCreateClient({ ...infoCreateClient, entreprise: e.target.value })} />
                                </div>

                                <div className='password'>
                                    <h2>
                                        Mot de passe:
                                    </h2>
                                    <div className='passwordWithGen'>
                                        <input type='text' placeholder='Mot de passe' value={infoCreateClient.password} />

                                        <button onClick={() => {
                                            setInfoCreateClient({ ...infoCreateClient, password: generateRandomPassword() });
                                        }}>
                                            Générer un mot de passe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        createModeDocument && itemSelectedName !== '' && (
                            <div className='itemContent'>
                                <div className='nameItem'>
                                    <h2>
                                        Nom du document:
                                    </h2>
                                    <input type='text' placeholder='Nom du document' value={infoCreateDocument.nomDocument} onChange={(e) => setInfoCreateDocument({ ...infoCreateDocument, nomDocument: e.target.value })} />
                                </div>

                                <div className='document'>
                                    <h2>
                                        Importer un document:
                                    </h2>
                                    <input type='file' accept='.pdf' onChange={(e) => handleFileUpload(e)} />
                                </div>

                                <div className='clientDoc'>
                                    <h2>
                                        Client:
                                    </h2>

                                    <AutoComplete
                                        label={'Sélectionner un client'}
                                        data={listclients}
                                        value={infoCreateDocument.client}
                                        setValue={(value) => setInfoCreateDocument({ ...infoCreateDocument, client: value })}
                                    />
                                </div>

                                <div className='typeDoc'>
                                    <h2>
                                        Type:
                                    </h2>
                                    <select value={infoCreateDocument.type} onChange={(e) => setInfoCreateDocument({ ...infoCreateDocument, type: e.target.value })}>
                                        <option value=''>Sélectionner un type</option>
                                        <option value='facture'>Facture</option>
                                        <option value='devis'>Devis</option>
                                        <option value='autre'>Autre</option>
                                    </select>
                                </div>
                            </div>
                        )
                    }

                    {
                        createModeClient && itemSelectedName !== '' || createModeDocument && itemSelectedName !== '' ? (
                            <div className='publishContent' onClick={() => publishItem()}>
                                <div className='publish'>
                                    <GoUpload size={25} color='black' />
                                    <h1>
                                        Publier
                                    </h1>
                                </div>
                            </div>
                        ) : (itemSelectedName !== '' ? (
                            <div className='publishContent'>
                                <div className='publish' onClick={() => deleteItem()}>
                                    <IoMdClose size={25} color='black' />
                                    <h1>
                                        Supprimer
                                    </h1>
                                </div>

                                <div className='publish' onClick={() => updateItem()}>
                                    <GoUpload size={25} color='black' />
                                    <h1>
                                        Editer
                                    </h1>
                                </div>
                            </div>
                        ) : null)
                    }
                    
                </div>

            </div>
            <Toaster/>
        </div>
    );
};

export default Dashboard;