import NavBar from '../../components/navBar/NavBar';
import './postuler.css';

import imgLogo from '../../style/mesimages/logo.png';
import { useEffect, useState } from 'react';

import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdOutlineWorkOutline } from "react-icons/md";
import { RiGraduationCapLine } from "react-icons/ri";

const Postuler = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [printLoader, setPrintLoader] = useState(false);  // Loader state

    const [offres, setOffres] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        cv: null,
    });

    const fetchOffres = () => {
        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            setOffres(data);
        }).catch((error) => {
            console.log(error);
        })
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "cv") {
            setFormData({ ...formData, cv: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const fileToBase64 = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                resolve(base64String);
            };

            reader.onerror = error => reject(error);

            reader.readAsDataURL(file);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Set the loader to true while submitting
        setPrintLoader(true);

        // Création de l'objet de données à envoyer
        const formDataObject = {
            nomComplet: formData.name,
            email: formData.email,
            message: formData.message,
            cv: await fileToBase64(formData.cv),
        };

        try {
            const response = await fetch(`${apiUrl}/postuler`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formDataObject),
            });

            if (response.ok) {
                alert("Candidature envoyée avec succès !");
                setFormData({
                    name: "",
                    email: "",
                    message: "",
                    cv: null,
                });
            } else {
                alert("Une erreur est survenue lors de l'envoi de la candidature.");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi de la candidature :", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
        }

        // Reset loader state after the response is received
        setPrintLoader(false);
    };

    const formatDate = (date) => {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    useEffect(() => {
        fetchOffres();
    }, []);

    return (
        <div className='recrutement'>
            <NavBar />

            {/* Loader Section */}
            {printLoader && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}


            <div className='container-job'>
                {
                    offres.filter(offre => offre.is_active).map((offre, index) => (
                        <div className="job-post" key={index}>
                            <div className="job-header">
                                <p className="publish-date">PUBLIÉ LE {formatDate(offre.date_creation)}</p>
                                <h3 className="sector">{offre.secteur}</h3>
                                <h1 className="job-title">{offre.intitule}</h1>
                                <p className="description">{offre.description}</p>
                                <a href={`${offre.link}`} target='_blank' className="apply-button">
                                    VOIR L'OFFRE →
                                </a>
                            </div>
                            <div className="job-details">
                                <ul>
                                    <li>
                                        <div className='icon'>
                                            <IoDocumentTextOutline size={20} />
                                        </div>
                                        {offre.type_emploi}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <IoIosTimer size={20} />
                                        </div>
                                        39h
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <IoLocationOutline size={20} />
                                        </div>
                                        {offre.lieu}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <LiaIndustrySolid size={20} />
                                        </div>
                                        {offre.secteur}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <MdOutlineWorkOutline size={20} />
                                        </div>
                                        {offre.annee_experience}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <RiGraduationCapLine size={20} />
                                        </div>
                                        {offre.diplome_requit}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))
                }
            </div>


            {/* <div className="job-post">
                <div className="job-header">
                    <p className="publish-date">PUBLIÉ LE 14 JAN. 2025</p>
                    <h3 className="sector">Industrie</h3>
                    <h1 className="job-title">Electrotechnicien F/H</h1>
                    <a href="#contact-form" className="apply-button">
                        POSTULER À CETTE OFFRE →
                    </a>
                </div>
                <div className="job-details">
                    <ul>
                        <li>
                            <div className='icon'>
                                <IoDocumentTextOutline size={20} />
                            </div>
                            CDI
                        </li>
                        <li>
                            <div className='icon'>
                                <IoIosTimer size={20} />
                            </div>
                            39h
                        </li>
                        <li>
                            <div className='icon'>
                                <IoLocationOutline size={20} />
                            </div>
                            Nord de Nancy, France
                        </li>
                        <li>
                            <div className='icon'>
                                <LiaIndustrySolid size={20} />
                            </div>
                            Industrie
                        </li>
                        <li>
                            <div className='icon'>
                                <MdOutlineWorkOutline size={20} />
                            </div>
                            Plus de 5 ans
                        </li>
                        <li>
                            <div className='icon'>
                                <RiGraduationCapLine size={20} />
                            </div>
                            CAP/BEP à BAC+2 en Electrotechnique
                        </li>
                    </ul>
                </div>
            </div> */}

            <div className="contact-form" id="contact-form">
                <h2>Candidature spontanée</h2>
                <form id="application-form" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label htmlFor="name">Nom complet</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Entrez votre nom complet"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Entrez votre email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Ajoutez un message pour accompagner votre candidature"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="cv">Télécharger votre CV</label>
                        <input
                            type="file"
                            id="cv"
                            name="cv"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit">
                        Envoyer ma candidature
                    </button>
                </form>
            </div>


            <footer id="footer" class="bg-color-secondary border-top-0 mt-0 custom-footer">
                <div class="container container-xl-custom py-md-4">
                    <div class="row justify-content-md-center py-5">
                        <div class="col-md-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
                            <a href="#"><img src={imgLogo} alt="Logo" class="img-fluid logo" /></a>
                        </div>
                        <div class="col-md-3 text-center text-md-start">
                            <p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Nous Retrouver</p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Adresse</p>
                            <p class="text-3 mb-2 text-color-light">13 rue du Chambré, 54760, Faulx - France</p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">TELEPHONE</p>
                            <p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">03 83 49 65 54</a></p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">EMAIL</p>
                            <p class="text-3 mb-2 "><a href="mailto:info@porto.com" class="text-color-light">contact@sarlperrollaz.fr</a></p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Heures d'ouverture</p>
                            <p class="text-3 mb-3 text-color-light">Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
                            <ul class="social-icons social-icons-dark social-icons-clean">
                                <li class="social-icons-linkedin">
                                    <a href="https://www.linkedin.com/in/didier-perrollaz-568b4b140/" target="_blank" title="Linkedin">
                                        <i class="fab fa-linkedin font-weight-semibold"></i>
                                    </a>
                                </li>
                                <li class="social-icons-facebook">
                                    <a href="https://www.facebook.com/Perrollaz54/" target="_blank" title="Facebook">
                                        <i class="fab fa-facebook-f font-weight-semibold"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center text-md-start mt-5 mt-md-0 mb-5 mb-lg-0">
                            <p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Navigation</p>
                            <div class="row opacity-7">
                                <div class="col-md-5">
                                    <p class="mb-0"><a href="#" class="text-3 text-color-light link-hover-style-1">Accueil</a></p>
                                    <p class="mb-0"><a href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} class="text-3 text-color-light link-hover-style-1">à propos</a></p>
                                    <p class="mb-0"><a href="/contact" class="text-3 text-color-light link-hover-style-1">Nous contacter</a></p>
                                    <p class="mb-0"><a href="/login" class="text-3 text-color-light link-hover-style-1">Se connecter</a></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="container container-xl-custom">
                    <div class="footer-copyright footer-copyright-style-2 bg-color-secondary">
                        <div class="py-2">
                            <div class="row py-4">
                                <div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                                    <p class="text-3 text-color-light opacity-7">Tal-Web construction. © 2023. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Postuler;
