import React, { useState, useEffect } from 'react';
import './gestionOffre.css';

import Modal from '../../components/modal/modal';
import ModalEdit from '../../components/modalEdit/modalEdit';
import { TbLogout2 } from "react-icons/tb";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdOutlineWorkOutline } from "react-icons/md";
import { RiGraduationCapLine } from "react-icons/ri";

import { Toaster, toast } from 'react-hot-toast';

const GestionOffre = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [infosEditOffre, setInfosEditOffre] = useState({});
    const [offres, setOffres] = useState([]);

    const [tabOffre, setTabOffre] = useState({
        date_creation: new Date(),
        intitule: '',
        secteur: '',
        type_emploi: '',
        lieu: '',
        annee_experience: '',
        diplome_requit: '',
        is_active: false,
        link: '',
        description: '',
    });

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (!token) {
            window.localStorage.clear();
            window.location.href = '/login';
        }
    }, []);

    useEffect(() => {
        fetchOffres();
    }, []);

    const fetchOffres = () => {
        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            setOffres(data);
        }).catch((error) => {
            console.log(error);
        })
    };


    const handleChange = (e) => {
        const { id, type, checked, value } = e.target;

        setTabOffre({
            ...tabOffre,
            [id]: type === "checkbox" ? checked : value,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(tabOffre);

        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },
            body: JSON.stringify(tabOffre),

        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
            toast.error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            setIsModalOpen(false);
            fetchOffres();
            toast.success('Offre ajoutée avec succès');
        }).catch((error) => {
            console.log(error);
        })

    };

    const formatDate = (date) => {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const changeStatus = (id, status) => {
        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },
            body: JSON.stringify({ is_active: status }),

        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
            toast.error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            fetchOffres();
            toast.success('Statut modifié avec succès');
        }).catch((error) => {
            console.log(error);
        })
    };

    const deleteOffre = (id) => {
        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },

        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
            toast.error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            fetchOffres();
            toast.success('Offre supprimée avec succès');
        }).catch((error) => {
            console.log(error);
        })
    };

    const editOffre = (id) => {
        const token = window.localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/poste-edit/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'userId': JSON.parse(window.localStorage.getItem('user')).user.id
            },
            body: JSON.stringify(tabOffre),

        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Une erreur est survenue');
            toast.error('Une erreur est survenue');
        }).then((data) => {
            console.log(data);
            fetchOffres();
            toast.success('Offre modifiée avec succès');
        }).catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className='offre'>
            <div className='headerDash'>
                <div className='left-dash-header'>
                    <h1>
                        sarl_perrollaz_gestion
                    </h1>

                    <a href='/dashboard'>
                        Gestion des documents
                    </a>
                </div>


                <div className='logout'>
                    <TbLogout2 size={30} />
                </div>
            </div>

            <div className='offre-content'>
                <div className='header-offre'>
                    <button onClick={() => setIsModalOpen(true)}>
                        Ajouter une offre
                    </button>
                </div>
            </div>


            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Ajouter une offre d'emploi"
            >
                <form className='form-offre' onSubmit={handleSubmit}>
                    <div className='container-form'>
                        <div className='form-left'>
                            <div className='input-group'>
                                <label htmlFor='titre'>Titre de l'offre</label>
                                <input type='text' id='intitule' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='description'>Description</label>
                                <textarea id='description' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='secteur'>Secteur d'activité</label>
                                <input type='text' id='secteur' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='type'>Type de contrat</label>
                                <input type='text' id='type_emploi' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='lieu'>Lieu de travail</label>
                                <input type='text' id='lieu' onChange={handleChange} />
                            </div>

                        </div>

                        <div className='form-right'>

                            <div className='input-group'>
                                <label htmlFor='experience'>Expérience</label>
                                <input type='text' id='annee_experience' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='diplome'>Diplôme</label>
                                <input type='text' id='diplome_requit' onChange={handleChange} />
                            </div>

                            <div className='input-group-check'>
                                <label htmlFor='isActif'>Afficher l'offre</label>
                                <input type='checkbox' id='is_active' onChange={handleChange} />
                            </div>

                            <div className='input-group'>
                                <label htmlFor='lien'>Lien LinkedIn</label>
                                <input type='text' id='link' onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <button type='submit'>Ajouter</button>
                </form>
            </Modal>

            {
                offres.length === 0 && (
                    <div className='no-offre'>
                        <h1>
                            Chargement des offres...
                        </h1>
                    </div>
                )
            }

            <div className='offre-list'>
                {offres.map((offre, index) => (
                    <div key={index} className='offre-item'>
                        <div className="job-post gest">
                            <div className="job-header">
                                <p className="publish-date">PUBLIÉ LE {formatDate(offre.date_creation)}</p>
                                <h3 className="sector">{offre.secteur}</h3>
                                <h1 className="job-title">{offre.intitule}</h1>

                                <div className='btn-gest'>
                                    <div className='activeOrNot'>
                                        <label htmlFor='is_active'>Activer</label>
                                        <input type='checkbox' id='is_active' onChange={changeStatus.bind(this, offre.id, !offre.is_active)} checked={offre.is_active} />
                                    </div>

                                    <button className='btn-edit' onClick={() => {
                                        setTabOffre(offre);
                                        setIsModalEditOpen(true);
                                    }}>
                                        Modifier
                                    </button>

                                    <button onClick={deleteOffre.bind(this, offre.id)}>
                                        Supprimer
                                    </button>
                                </div>
                                
                            </div>
                            <div className="job-details">
                                <ul>
                                    <li>
                                        <div className='icon'>
                                            <IoDocumentTextOutline size={20} />
                                        </div>
                                        {offre.type_emploi}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <IoIosTimer size={20} />
                                        </div>
                                        39h
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <IoLocationOutline size={20} />
                                        </div>
                                        {offre.lieu}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <LiaIndustrySolid size={20} />
                                        </div>
                                        {offre.secteur}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <MdOutlineWorkOutline size={20} />
                                        </div>
                                        {offre.annee_experience}
                                    </li>
                                    <li>
                                        <div className='icon'>
                                            <RiGraduationCapLine size={20} />
                                        </div>
                                        {offre.diplome_requit}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Toaster />

            <ModalEdit isOpen={isModalEditOpen} onClose={() => setIsModalEditOpen(false)} title="Modifier une offre d'emploi" handleSubmitEdit={() => { editOffre(tabOffre.id); }} offreInfos={tabOffre} setTabOffre={setTabOffre} />
        </div>
    );
};

export default GestionOffre;
